export const dentalClinicRoutes = {
  path: "/dental-clinic",
  meta: { requiresAuth: true },
  component: () => import("./Main"),
  children: [
    {
      path: "stock-config",
      component: () => import("../logistic-module/stock/Config.vue")
    },
    {
      path: "stock",
      component: () => import("../logistic-module/stock/Index.vue")
    },
    {
      path: "stores",
      component: () => import("../logistic-module/stores/Index.vue")
    },
    {
      path: "logistic/inputs",
      component: () => import("../logistic-module/inputs/Index.vue")
    },
    {
      path: "logistic/outputs",
      component: () => import("../logistic-module/outputs/Index.vue")
    },
    {
      path: "requirements",
      component: () => import("../logistic-module/requirements/Index.vue")
    },
    {
      path: "suppliers",
      component: () => import("../logistic-module/suppliers/Index.vue")
    },
    {
      path: "sales",
      component: () => import("../store-module/sales/Index.vue")
    },
    {
      path: "current-cash-register",
      component: () => import("../admin-module/cashregisters/MyBox.vue")
    },
    {
      path: "cash-registers",
      component: () => import("../admin-module/cashregisters/Index.vue")
    },
    {
      path: "entitys",
      component: () => import("../admin-module/entitys/Index.vue")
    },
    {
      path: "areas",
      component: () => import("../admin-module/areas/Index.vue")
    },
    {
      path: "emit-liquidations",
      component: () => import("../admin-module/liquidations/Index.vue")
    },
    {
      path: "sources",
      component: () => import("../admin-module/sources/Index.vue")
    },
    {
      path: "reasons",
      component: () => import("../admin-module/reasons/Index.vue")
    },
    {
      path: "report/entitys",
      component: () => import("../admin-module/reports/ReportEntitys.vue")
    },
    {
      path: "report/finantial",
      component: () => import("../admin-module/reports/Finantial.vue")
    },
    {
      path: "agenda",
      component: () => import("./agenda/Main"),
      children: [
        {
          path: "",
          component: () => import("./agenda/Index.vue"),
          name: "dc-agenda"
        },
        { path: "to-print", component: () => import("./agenda/ToPrint") }
      ]
    },

    { path: "", redirect: "/dental-clinic/agenda" },

    {
      path: "financing-status",
      component: () => import("./financings/ReportStatus")
    },

    {
      path: "weekly-agenda/:employee_id",
      component: () => import("./agenda/WeeklyAgenda.vue"),
      props: true
    },
    { path: "patient-files", component: () => import("./patient-files/Index") },
    {
      path: "treatments",
      component: () => import("./treatments/Index"),
      props: { initiated: 1 }
    },
    // {
    //   path: "treatments-no-ini",
    //   component: () => import("./treatments/Index"),
    //   props: { initiated: 0 }
    // },
    {
      path: "config/odontogram-menu",
      component: () => import("./odontogram/menu/Index")
    },
    {
      path: "config/odontogram",
      component: () => import("./odontogram/config/Index")
    },
    {
      path: "config/product-states",
      component: () => import("./odontogram/product-states/Index")
    },
    {
      path: "config/doctors",
      //component: () => import("./liquidations/Index.vue")
      component: () => import("./doctors/DoctorList.vue")
    },
    {
      path: "liquidations",
      component: () => import("./liquidations/Main"),
      children: [
        { path: "", component: () => import("./liquidations/Liquidations") },
        {
          path: "payments/:employee_id",
          component: () => import("./liquidations/Payments"),
          props: true
        },
        {
          path: "maked",
          component: () => import("./liquidations/Maked")
        },
        {
          path: ":employee_liquidation_id",
          component: () => import("./doctors/Payments"),
          props: true
        }
      ]
    },
    {
      path: "laboratories",
      component: () => import("./laboratories/main"),
      children: [
        { path: "", component: () => import("./laboratories/index") },
        {
          path: "disables",
          component: () => import("./laboratories/index"),
          props: { enables: 0 }
        },
        {
          path: "jobs",
          component: () => import("./laboratory-jobs/Index")
        },
        {
          path: ":supplier_id",
          component: () => import("./laboratories/show"),
          props: true
        }
      ]
    },
    // {
    //   path: "laboratory-prices",
    //   component: () => import("./laboratory-jobs/Prices")
    // },
    {
      path: "financings/:id",
      component: () => import("./financings/Financing"),
      props: true
    },
    { path: "contact-tasks", component: () => import("./contact-tasks/Index") },
    {
      name: "dc-proforms",
      path: "proforms",
      component: () => import("./proforms/Index")
    },
    {
      path: "proform",
      component: () => import("./proforms/PageCreateProform")
    },
    {
      path: "proforms/:proform_id",
      component: () => import("./proforms/FormPage"),
      props: true
    },
    {
      path: "products",
      component: () => import("./products/ArancelPrecios.vue"),
      children: [
        {
          path: "",
          component: () => import("./products/categorys/CategoryList.vue"),
        },
        {
          path: "list",
          component: () => import("./products/ProductList.vue"),
          props: true
        },
        {
          path: ":categorie_id",
          component: () => import("./products/CategoryIndex.vue"),
          props: true
        },
      ]
    },

    {
      path: "reports/new-clients",
      component: () => import("./reports/NewClients")
    },
    {
      path: "reports/proforms",
      component: () => import("./reports/NewPatients")
    },
    {
      path: "reports/no-use-proforms",
      component: () => import("./reports/NoUseProforms")
    },
    {
      path: "report/monitor",
      component: () => import("./reports/Monitor.vue"),
    },
    // { path: 'proform-othodontic', component: () => import('./orthodontics/Proform') },
    // { path: 'proform-othodontic/:proform_id', component: () => import('./orthodontics/Proform'), props: true },
    {
      path: "patients/:client_id",
      component: () => import("./patients/Index"),
      props: true,
      children: [
        {
          path: "contact-tasks",
          component: () => import("./contact-tasks/Index"),
          props: true
        },
        {
          path: "alerts",
          component: () => import("./patients/Alerts"),
          props: true
        },
        {
          path: "comments",
          component: () => import("./patients/Comments"),
          props: true
        },
        {
          path: "quotes",
          component: () => import("./patients/Quotes"),
          props: true
        },
        {
          path: "profile",
          component: () => import("./patients/Profile"),
          props: true
        },
        // {
        //   path: "",
        //   component: () => import("./patients/OwnOdontogram"),
        //   props: true
        // },
        // {
        //   path: "",
        //   component: () => import("./proforms/SingleView"),
        //   props: true
        // },
        {
          path: "",
          name: "client-profile",
          component: () => import("./treatments/Treatments"),
          props: true
        },
        {
          path: "proforms",
          component: () => import("./patients/Proforms"),
          props: true
        },

        {
          path: "treatment-plans",
          component: () => import("./treatments/Treatments"),
          props: true
        },
        {
          path: "treatment-plans-no-ini",
          component: () => import("./treatments/Treatments"),
          props: (route) => ({
            initiated: 0,
            client_id: route.params.client_id
          })
        },
        {
          path: "treatment-plans/:id",
          component: () => import("./treatments/Treatment"),
          props: true
        },
        {
          path: "files",
          component: () => import("./patients/PatientFile.vue"),
          props: true
        },
        {
          path: "sales-paid-out",
          component: () => import("./patients/PaidOut"),
          props: true
        },
        {
          path: "to-pay",
          component: () => import("./patients/ToPay"),
          props: true
        },
        {
          path: "orthodontics/:treatment_plan_id",
          component: () => import("./orthodontics/Index"),
          props: true,
          children: [
            {
              path: "",
              component: () => import("./orthodontics/control-page"),
              props: true
            },
            {
              path: "proform",
              component: () => import("./orthodontics/Proform")
            },
            {
              path: "anamnesis",
              component: () => import("./orthodontics/Anamnesis")
            },
            {
              path: "plan",
              component: () => import("./orthodontics/Plan"),
              props: true
            },
            {
              path: "program-quotes",
              component: () => import("./orthodontics/ProgramQuotes"),
              props: true
            }
          ]
        }
      ]
    }
  ]
};
